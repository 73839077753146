// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/App.css */
.App {
  font-family: 'Courier New', Courier, monospace;
  color: #5e8a3a;
  background-color: #080808;
  padding: 20px;
  min-height: 100vh;
}

.cli-area {
  text-align: left;
}

.cli-output {
  white-space: pre-wrap;
}

.cli-input {
  display: flex;
  align-items: center;
}

.input-prefix {
  margin-right: 5px;
}

input {
  background-color: #080808;
  color: #5e8a3a;
  border: none;
  padding: 5px;
  width: 90%;
  font-family: 'Courier New', Courier, monospace;
}

input:focus {
  outline: none;
}

.cursor {
  background-color: #5e8a3a;
  margin-left: 5px;
  width: 8px;
  height: 18px;
  animation: blink 1s steps(5, start) infinite;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

.generated-image {
  max-width: 100%;
  border: 1px solid #5e8a3a;
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,8CAA8C;EAC9C,cAAc;EACd,yBAAyB;EACzB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,YAAY;EACZ,UAAU;EACV,8CAA8C;AAChD;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,UAAU;EACV,YAAY;EACZ,4CAA4C;AAC9C;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,gBAAgB;AAClB","sourcesContent":["/* src/App.css */\n.App {\n  font-family: 'Courier New', Courier, monospace;\n  color: #5e8a3a;\n  background-color: #080808;\n  padding: 20px;\n  min-height: 100vh;\n}\n\n.cli-area {\n  text-align: left;\n}\n\n.cli-output {\n  white-space: pre-wrap;\n}\n\n.cli-input {\n  display: flex;\n  align-items: center;\n}\n\n.input-prefix {\n  margin-right: 5px;\n}\n\ninput {\n  background-color: #080808;\n  color: #5e8a3a;\n  border: none;\n  padding: 5px;\n  width: 90%;\n  font-family: 'Courier New', Courier, monospace;\n}\n\ninput:focus {\n  outline: none;\n}\n\n.cursor {\n  background-color: #5e8a3a;\n  margin-left: 5px;\n  width: 8px;\n  height: 18px;\n  animation: blink 1s steps(5, start) infinite;\n}\n\n@keyframes blink {\n  to {\n    visibility: hidden;\n  }\n}\n\n.generated-image {\n  max-width: 100%;\n  border: 1px solid #5e8a3a;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
