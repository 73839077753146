import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [input, setInput] = useState('');
  const [commands, setCommands] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('generating');
  const API_BASE_URL = 'https://api.roboai.cc';

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        executeCommand(input);
        setInput('');
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [input, API_BASE_URL]); 

  useEffect(() => {
    let intervalId;

    if (isLoading) {
      let dotCount = 0;
      intervalId = setInterval(() => {
        dotCount = (dotCount + 1) % 4;
        setLoadingText(`generating${'.'.repeat(dotCount)}`);
      }, 500);
    } else {
      setLoadingText('generating');
    }

    return () => clearInterval(intervalId);
  }, [isLoading]);

  const executeCommand = async (command) => {
    const args = command.split(' ');
    switch (args[0]) {
      case '/start':
        setCommands((prev) => [...prev, { type: 'text', content: [
          'welcome to roboai - use a command below:',
          '',
          '/rules',
          '/generate <text_input> - prompt = create a robo',
          '/contract',
          '/twitter',
          '/exchange_listings',
        ].join('\n') }]);
        break;
      case '/generate':
        const prompt = args.slice(1).join(' ');
        setIsLoading(true); // Start loading
        try {
          const response = await axios.post(`https://api.roboai.cc/api/generate`, { prompt });
          console.log("API Response:", response);
          setIsLoading(false);
          setIsLoading(false);
          const imageUrl = `https://api.roboai.cc${response.data.imageUrl}`;
          // Include both the image URL and alt text in the command for rendering
          setCommands((prev) => [...prev, { type: 'image', content: imageUrl, alt: `${prompt} ` }]);
        } catch (error) {
          console.error('Error generating image:', error);
          setIsLoading(false); // Stop loading in case of error
          setCommands((prev) => [...prev, { type: 'text', content: 'Failed to generate image.' }]);
        }
        break;
      case '/contract':
        setCommands((prev) => [...prev, { type: 'text', content: '58Bs5J8RwJRfv5WYXrafiRk8U8bJ6WpNSr1iT1Dspump' }]);
        break;
        case '/twitter':
          window.open('https://twitter.com/roboai_', '_blank');
        break;
      case '/rules':
        setCommands ((prev) =>[...prev, {type: 'text', content:'Players will recieve the reward.'}]);
        break;
      case '/telegram':
        window.open('pending...', '_blank');
        break;
      case '/exchange_listings':
        setCommands((prev) => [...prev, { type: 'text', content: 'loading [40.4%]' }]);
        break;
      default:
        setCommands((prev) => [...prev, { type: 'text', content: 'type /start to begin.' }]);
        break;
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>robo ai</p>
      </header>
      <section className="cli-area">
        <div className="cli-output">
          {commands.map((cmd, index) => (
            cmd.type === 'text' ? (
              <div key={index}>{cmd.content}</div>
            ) : (
              // Use the alt attribute for the image to include the prompt text
              <img key={index} src={cmd.content} alt={`${cmd.alt} `} className="generated-image" />
            )
          ))}
          {isLoading && <div>{loadingText}</div>}
        </div>
        <div className="cli-input">
          <span className="input-prefix"></span>
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Enter command"
            autoFocus
          />
          <div className="cursor"></div>
        </div>
      </section>
    </div>
  );
}

export default App;
